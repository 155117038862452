import React, { useState, useEffect } from 'react'


const RewardPoints = ({ rewardpoint }) => {
  
  const [mouseEnter, setmouseEnter] = useState('')
  const handleMouseEnter = (id) => {
      setmouseEnter(id)
  }
  const handleMouseLeave = (e) => {
    setmouseEnter("")
  }

   return( 
    rewardpoint.map((rp, index) => {
      return(
        <div
        key={index}
        className={`${rp.parentClass} ${mouseEnter === index ? "darken" : ""}`}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={() => handleMouseLeave()}
      >
          {/* <picture>
            <source className={rp.class} loading="lazy" srcset={rp.img} type="image/webp" alt={rp.heading} />
            <img className={rp.class} loading="lazy" src={rp.img} alt="RR-Buyers-Guide-Thumbnail" />
          </picture> */}
        <img className={rp.class} src={rp.img} alt={rp.heading} />
        <h3 className={`${rp.headingClass} + ${mouseEnter !== index ? "normalHeading" : "animateHeading"}`}>
          {rp.heading}
        </h3>
        <p className={`pointer-events-none text-gray-100 md:text-xs lg:text-base xl:text-lg absolute w-11/12 pr-3 ` + `${mouseEnter === index ? "showDescription" : "hidden"}`}>{rp.description}</p>
      </div>
      )
    })
  )
}

export default RewardPoints
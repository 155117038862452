import React from 'react'
import ReactDOM from "react-dom"
import HubspotForm from "react-hubspot-form"


const WithProductPageForm = ({ children, HSformID }) => {
  return (
        <>
            <div className='w-full pt-0 px-4 pb-8 lg:p-8'>
                <div class="card-1 md:w-5/6 xl:w-full mx-auto rnrForm xl:mt-0 flex flex-col place-items-center px-4 lg:px-6 py-7 lg:py-10 bg-indigo-50 frostedTransparentBG rounded-xl z-10 relative" style={{borderWidth: "0.3px", borderColor: "#ddd" }} id="form">
                    {/* <h2 class="text-2xl lato text-purple-100 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from making your employees happy</h2> */}
                    <div class="w-full flex justify-center items-center lato rewards team">
                        <div class="flex justify-center items-center">
                            <HubspotForm
                            portalId="6153052"
                            formId="d2e2c7bf-c255-4b72-a236-53240ebeed17"
                            onSubmit={() => console.log("Submit!")}
                            onReady={form => console.log("Form ready!")}
                            loading={<div>Loading...</div>}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='h-96 w-96 rounded-full absolute left-1/3 -bottom-20' style={{ background: "#464678", zIndex: '3' }}></div>
            <div className='hidden xl:block h-40 w-40 rounded-full absolute ml-10 left-1/2 top-1/4' style={{ background: "#464678", zIndex: '3' }}></div> */}
        </>
    )
}
const renderProductPageForm=()=>{
    if(typeof window !== "undefined"){ 
        let id = document.querySelector("#productPageForm")
        if (id != null){
            ReactDOM.render(
                <WithProductPageForm />,
                id
            )
        }
    }
}

setTimeout(renderProductPageForm,100)

export default WithProductPageForm